import Vue from 'vue'
import App from './App.vue'
import utils from './utils'
import 'lib-flexible'
import router from './router/router'
import md5 from 'js-md5'
import api from './http/api'
import title from 'vue-wechat-title'





Vue.use(title)
utils.PrintEnvInfo()
Vue.config.productionTip = false
Vue.prototype.$api = api
Vue.prototype.$md5 = md5



const defaultTitle = '五一活动'
router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title : defaultTitle
  next()
})

new Vue({
  render: h => h(App),
  router: router
}).$mount('#app')
