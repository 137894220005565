import Vue from 'vue'

const isProject = true
const activityApiUrl = isProject ? "https://api.activity.meisheapp.com" : "http://testapi.activity.meisheapp.com";
var apiUrl = !isProject ? 'http://test.api.meisheapp.com' : 'https://api.meisheapp.com';
const webAPPUrl = isProject ? 'https://webapp.meisheapp.com' : 'http://test.webapp.meisheapp.com';
const activityUrl = isProject ? 'https://activity.meisheapp.com' : 'http://test.activity.meisheapp.com';
const couponUrl = isProject ? 'https://coupon.meisheapp.com' : 'http://test.coupon.meisheapp.com';

const projectName = 'wuyi';
const projectPath = `/{$projectName}}/`;

const ua = window.navigator.userAgent;
const isIos = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

let utils = {
    isProject,
    projectName,
    projectPath,
    activityApiUrl,
    apiUrl,
    webAPPUrl,
    activityUrl,
    couponUrl
};

utils.Console = function(option){
    const title = option.title;
    const content = option.content;
    const backgroundColor = option.backgroundColor;
    const arg = [
        "%c ".concat(title, " %c ").concat(content, " "),
        "padding: 1px; border-radius: 3px 0 0 3px; color: #fff; background: ".concat(
        "#606060",
        ";"
        ),
        "padding: 1px; border-radius: 0 3px 3px 0; color: #fff; background: ".concat(
        backgroundColor,
        ";"
        )
    ];
    window.console && typeof window.console.log === "function" &&  window.console.log.call(null, ...arg);
}

utils.PrintEnvInfo = function(){
    utils.Console({
        title: "Build Date",
        // eslint-disable-next-line no-undef
        content: MEISHE_BUILD_DATE,
        backgroundColor: "#1475b2"
      });
      utils.Console({
        title: "Commit",
        // eslint-disable-next-line no-undef
        content: MEISHE_COMMITHASH,
        backgroundColor: "#00B294"
      });

      utils.Console({
        title: "Branch",
        // eslint-disable-next-line no-undef
        content: MEISHE_BRANCH,
        backgroundColor: "#FFB900"
      });
      
}
// 验证浏览器方法
utils.isBrowser = function (name) {
  var ret = false;
  var userAgent = navigator.userAgent.toLowerCase();
  switch (name.toLowerCase()) {
      case "opera":
          ret = /opera/.test(userAgent);
          break;
      case "ie":
          ret = /msie/.test(userAgent) && !/opera/.test(userAgent);
          break;
      case "ie6":
          ret = /msie 6.0/.test(userAgent);
          break;
      case "safari":
          ret = /webkit/.test(userAgent);
          break;
      case "ff":
          ret = /firefox/.test(userAgent);
          break;
      case "wx":
          ret = /micromessenger/.test(userAgent);
          break;
      case 'wb':
          ret = /weibo/.test(userAgent);
          break;
      case 'qq':
          ret = /qq/.test(userAgent);
          break;
  }
  return ret;
},
utils.cookie= {
  set: function (name, value, seconds) {
      var d = new Date();
      d.setTime(d.getTime() + (seconds * 1000));
      document.cookie = name + "=" + value + "; expires=" + d.toUTCString();
  },
  get: function (name) {
      var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
      if (arr = document.cookie.match(reg)) {
          return unescape(arr[2]);
      }
      return "";
  },
  clear: function (name) {
      utils.cookie.set(name, "", -1);
  }
},
utils.JOIN_ACTIVITY = (id, name, themeId, page, isSupportTheme, templateId) => {
    if (isIos) {
        window.webkit.messageHandlers.jsCallNative.postMessage({
            key: "JOINACTIVITY",
            value: {
                id: Number(id),
                name: name,
                themeId: themeId, // 主题id,
                page: Number(page), // 1 - 拍摄  2 - 素材选择 3 - 新模板
                supportedAspectRatio: Number(isSupportTheme),
                // 模板id
                templateId: Number(templateId),
            },
        });
    } else {
        var message = JSON.stringify({
            key: "JOINACTIVITY",
            value: {
                id: Number(id),
                name: name,
                themeId: themeId, // 主题id,
                page: Number(page), // 1 - 拍摄  2 - 素材选择 3 - 新模板
                supportedAspectRatio: Number(isSupportTheme),
                // 模板id
                templateId: Number(templateId),
            },
        });
        JSInteraction.setParams(message);
    }
  }
  
// 播放视频
utils.PLAY_VIDEO = function (id) {
    if (isIos) {
        window.webkit.messageHandlers.jsCallNative.postMessage({
            key: 'PLAYVIDEO',
            value: {
                id: Number(id)
            }
        })
    } else {
        var message = JSON.stringify({
            key: 'PLAYVIDEO',
            value: {
                id: id
            }
        })
        JSInteraction.setParams(message)
    }
}
// 关闭当前页面
utils.CLOSE_PAGE = function(id){
    if (isIos) {
        window.webkit.messageHandlers.jsCallNative.postMessage({
            key: 'CLOSEWEBVIEW',
            value: {}
        })
    } else {
        var message = JSON.stringify({
            key: 'CLOSEWEBVIEW',
            value: {}
        })
        JSInteraction.setParams(message)
    }
}


// 美枚页面
utils.MEIMEIINFO = function(id){
    if (isIos) {
        window.webkit.messageHandlers.jsCallNative.postMessage({
            key: 'MEIMEIINFO',
            value: {}
        })
    } else {
        var message = JSON.stringify({
            key: 'MEIMEIINFO',
            value: {}
        })
        JSInteraction.setParams(message)
    }
}

//获取签名
utils.getSign = (URL)=>{
    let app_key = "3ab58649236688fd"
    let secret_key ="0ebadf1ad6c24625a46d3d02d11730f7"
    let date = String(new Date().valueOf()).slice(0, 10)
    var sign = Vue.prototype.$md5("app_key=" +app_key +"&" +"timestamp=" +date +"&" +"uri=" + URL +"&" +"secret_key=" +secret_key);
    return {app_key, date, sign};
}

//格式化URL
utils.getRequest = () => {
    var url = window.location.search; //获取url中"?"符后的字串
    var theRequest = new Object();
    if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = decodeURI(
                strs[i].split("=")[1]
            );
        }
    }
    return theRequest;
}

//打开或下载云美摄
utils.downLoad=()=>{
    var ua = window.navigator.userAgent;
    var isIos = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isIos) {
        window.open('http://itunes.apple.com/cn/app/id928359827?mt=8');
    } else if (ua) {
        window.open('http://a.app.qq.com/o/simple.jsp?pkgname=com.cdv.video360&from=singlemessage');
    }
},
utils.wx = {
    isWeiXin: function () {
        return utils.isBrowser('wx');
    },
    shareConfig: function (options, callback) {
        var defaults = {
            debug: false,
            shared: true,
            title: document.title,
            desc: document.title,
            link: window.location.href,
            imgUrl: '',
            jsApiList: ['hideOptionMenu', 'hideMenuItems', 'onMenuShareTimeline', 'onMenuShareAppMessage', 'onMenuShareQQ', 'onMenuShareWeibo', 'onMenuShareQZone'],
            menuList: ["menuItem:favorite", "menuItem:copyUrl", "menuItem:openWithQQBrowser", "menuItem:openWithSafari"],
            appId: '',
            timestamp: '',
            nonceStr: '',
            signature: '',
        };
        var opts = $.extend(defaults, options);
        wx.config({
            debug: opts.debug,
            appId: opts.appId,
            timestamp: opts.timestamp,
            nonceStr: opts.nonceStr,
            signature: opts.signature,
            jsApiList: opts.jsApiList
        });
        wx.ready(function () {
            if (!opts.shared) {
                wx.hideOptionMenu();
            } else {
                wx.hideMenuItems({
                    menuList: opts.menuList
                });
                wx.onMenuShareTimeline({
                    title: opts.title,
                    link: opts.link,
                    imgUrl: opts.imgUrl,
                    success: function (res) {
                        callback(res);
                    },
                    cancel: function () {
                    }
                });
                wx.onMenuShareAppMessage({
                    title: opts.title,
                    desc: opts.desc,
                    link: opts.link,
                    imgUrl: opts.imgUrl,
                    success: function (res) {
                        callback(res);
                    },
                    cancel: function () {
                    }
                });
                wx.onMenuShareQQ({
                    title: opts.title,
                    desc: opts.desc,
                    link: opts.link,
                    imgUrl: opts.imgUrl,
                    success: function (res) {
                        callback(res);
                    },
                    cancel: function () {
                    }
                });
                wx.onMenuShareWeibo({
                    title: opts.title,
                    desc: opts.desc,
                    link: opts.link,
                    imgUrl: opts.imgUrl,
                    success: function (res) {
                        callback(res);
                    },
                    cancel: function () {
                    }
                });
                wx.onMenuShareQZone({
                    title: opts.title,
                    desc: opts.desc,
                    link: opts.link,
                    imgUrl: opts.imgUrl,
                    success: function (res) {
                        callback(res);
                    },
                    cancel: function () {
                    }
                });
            }
        });
        wx.error(function (res) {
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。;
            console.log(res);
        });
    },
}

export default utils;